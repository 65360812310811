
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { ApiBase, ApiMerchant } from "@/core/api";
import {
  ChannelOption,
  MerchantOption,
  TaggingItem,
} from "@/core/directive/interface/common";
import _ from "lodash";
import { NumberOrString } from "@/core/directive/type/common";
import { MerchantInvoiceTableFilter } from "@/core/directive/interface/merchantInvoice";
import CommonChannelOption from "@/components/layout/CommonChannelOption.vue";

export default defineComponent({
  components: { CommonChannelOption },
  name: "merchant-invoice-dropdown",
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref<MerchantInvoiceTableFilter>({
      invoice_number: "",
      status: [],
      merchant: "",
      channel: "",
      external_order_number: "",
      invoice_date_end: "",
      invoice_date_start: "",
    });

    const options = ref({
      scourceOptions: [] as TaggingItem[],
      typeOptions: [] as TaggingItem[],
      statusOptions: [] as TaggingItem[],
      merchantLoading: false,
      merchantOptions: [] as MerchantOption[],
      channelLoading: false,
      channelOptions: [] as ChannelOption[],
    });

    const getSalesChannelsData = (id?: NumberOrString) => {
      formData.value.channel = "";
      options.value.channelOptions = [];
      getChannelSourceData(id);
    };

    const getChannelSourceData = async (id?: NumberOrString) => {
      options.value.channelLoading = true;
      const { data } = await ApiMerchant.getSalesChannelsData({
        max_item: "all",
        merchant_id: id,
        status: "10",
      });
      options.value.channelLoading = false;
      if (data.code == 0) {
        options.value.channelOptions = data.data;
      }
    };

    const getMerchantSourceData = async (value, id?) => {
      options.value.merchantLoading = true;
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      options.value.merchantLoading = false;
      const { data } = await ApiBase.getMerchantSourceData(params);
      if (data.code == 0) {
        options.value.merchantOptions = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["order_request_invoice_status"],
      });
      if (data.code == 0) {
        options.value.statusOptions =
          data.data.order_request_invoice_status.items;
      }
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      getTaggingData();
    });

    return {
      t,
      formData,
      formRef,
      options,
      getSalesChannelsData,
      searchMerchantSourceItems,
      submit,
      handleReset,
    };
  },
});
