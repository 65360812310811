import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "modal_import_merchant_invoice",
  ref: "importMerchantInvoiceRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  class: "modal-header",
  id: "kt_modal_import_merchant_invoice_header"
}
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = {
  "data-bs-dismiss": "modal",
  class: "btn btn-icon btn-sm btn-active-icon-primary"
}
const _hoisted_7 = { class: "svg-icon svg-icon-1" }
const _hoisted_8 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_9 = { class: "el-upload__tip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.t("common.import")), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _withDirectives((_openBlock(), _createBlock(_component_el_upload, {
            ref: "uploadRef",
            multiple: "",
            "file-list": _ctx.fileList,
            "auto-upload": false,
            "show-file-list": true,
            accept: ".pdf,.zip",
            limit: 10,
            headers: _ctx.uploadHeaders,
            "on-change": _ctx.onUploadChange,
            "on-exceed": _ctx.onUploadExceed,
            "on-error": _ctx.onUploadError,
            "on-remove": _ctx.onUploadRemove
          }, {
            tip: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.t("common.uploadAcceptSize", ["pdf, zip", "100M"])), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                icon: _ctx.Upload
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("orderRequest.clickToUpload")), 1)
                ]),
                _: 1
              }, 8, ["icon"])
            ]),
            _: 1
          }, 8, ["file-list", "headers", "on-change", "on-exceed", "on-error", "on-remove"])), [
            [_directive_loading, _ctx.uploadLoading]
          ])
        ])
      ])
    ])
  ], 512))
}