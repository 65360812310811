/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-09-30 14:36:54
 * @LastEditors: xiang.gao 57524479+GaoXiang0403@users.noreply.github.com
 * @LastEditTime: 2024-03-12 14:25:14
 */
import { CommonDateType, CommonSwtich, NumberOrString } from "../type/common";
import { CurrencyType } from "../type/order";
import {
  InvoiceStatus,
  OrderRequestProgress,
  OrderRequestType,
} from "../type/orderRequest";
import { ShipmentStatus } from "../type/shipment";

export const orderRequestOverviewData: OrderRequestOverview = {
  id: 0,
  type: OrderRequestType.MerchantOrder,
  request_number: "",
  request_date: "",
  request_expiry_date: "",
  order_progress: OrderRequestProgress.Draft,
  merchant: 0,
  channel: 0,
  invoice_address: 0,
  shipping_address: 0,
  shipping_charges: "0.0000",
  shipping_tax_rate: 0.0,
  priority: 0,
  overall_notes: "",
  reference: "",
  __show: {
    order_progress: "",
    merchant: "",
    channel: "",
    channel_type: "",
    settlement_entity_name: "",
    invoice_address: {
      id: 0,
      parent_id: 0,
      identifier_no: "",
      name: "",
      type: 0,
      vat_no: "",
      country_iso_2: "",
      city: "",
      address: "",
      zip_code: "",
      business_entity_id: 0,
      is_default: 0,
    },
    shipping_address: {
      id: 0,
      parent_id: 0,
      identifier_no: "",
      name: "",
      type: 0,
      vat_no: "",
      country_iso_2: "",
      city: "",
      address: "",
      zip_code: "",
      business_entity_id: 0,
      is_default: 0,
    },
    sale_order_number: [],
    external_order_number: [],
    brand: [],
    currency_code: "€",
    total_amount: "0",
    total_sku: 0,
    total_pcs: 0,
    total_invoice: 0,
    total_shipment: 0,
    representative: {
      name: "",
      avatar: "",
    },
    tax_rates: [],
  },
  relation_items: [],
  relation_shipment: [],
};

export const orderRequestItemData: OrderRequestItemTable = {
  id: 0,
  merchant_request_id: 0,
  position: "",
  article_no: "",
  brand_name: "",
  article_name: "",
  size: "",
  color: "",
  request_qty: 0,
  confirmed_qty: 0,
  shipped_qty: 0,
  shipping_qty: 0,
  rejected_qty: 0,
  max_qty: 0,
  relation_fulfillment: [],
  relation_shipment: [],
  confirmed_flag: CommonSwtich.InActive,
  shipped_flag: CommonSwtich.InActive,
  isChecked: false,
  __show: {
    product_gallery: "",
    product_name: "",
    brand_name: "",
    article_no: "",
    article_name: "",
    color: "",
    size: "",
  },
};

export const orderRequestItemSubData: OrderRequestItemSubTable = {
  id: 0,
  request_items_id: 0,
  merchant_order_number: "",
  confirmation_date: "",
  merchant_invoice_number: "",
  shipping_date: "",
  service_provider: "",
  tracking_no: "",
  shipping_qty: 1,
  __show: {
    service_provider: "",
  },
};

export const orderRequestInvoiceData: OrderRequestInvoice = {
  id: 0,
  merchant_request_id: 0,
  invoice_number: "",
  status: InvoiceStatus.Pending,
  invoice_date: "",
  currency: "EUR",
  total_amount: "0.00",
  external_order_number: "",
  invoice_file: 0,
  shipping_charges: "0.00",
  shipping_tax_rate: 0,
  merchant: "",
  channel: "",
  overall_notes: "",
  business_entity_id: 0,
  accounting_month: "",
  payment_date: "",
  price_calc_mode: "net",
  vat_refundable: CommonSwtich.InActive,
  __show: {
    status: "",
    invoice_file: "",
    file_size: 0,
    taxRates: [],
  },
  relation_invoice_item: [],
  relation_request: {
    id: 0,
    created_at: "",
    updated_at: "",
    deleted_at: "",
    created_uid: 0,
    updated_uid: 0,
    deleted_uid: 0,
    type: OrderRequestType.MerchantOrder,
    request_number: "",
    request_date: "",
    request_expiry_date: "",
    order_progress: OrderRequestProgress.New,
    merchant: 0,
    channel: 0,
    invoice_address: 0,
    shipping_address: 0,
    shipping_charges: "0.00",
    shipping_tax_rate: 0,
    priority: 0,
    reference: "",
    overall_notes: "",
  },
};

export const orderRequestInformationData: OrderRequestInformation = {
  id: 0,
  request_number: "",
  request_date: "",
  request_expiry_date: "",
  order_progress: 0,
  merchant: 0,
  channel: 0,
  invoice_address: 0,
  shipping_address: 0,
  relation_items: [],
  __show: {
    order_progress: "",
    merchant: "",
    channel: "",
  },
};

export interface OrderRequestItemTable {
  id: number;
  merchant_request_id: number;
  position: string;
  article_no: string;
  brand_name: string;
  article_name: string;
  size: string;
  color: string;
  request_qty: number;
  confirmed_qty: number;
  shipped_qty: number;
  shipping_qty: number;
  rejected_qty: number;
  max_qty: number;
  relation_fulfillment: OrderRequestItemRelationFulfillmentTable[];
  relation_shipment: OrderRequestItemSubTable[];
  confirmed_flag: number;
  shipped_flag: number;
  isChecked: boolean;
  __show: OrderRequestOverviewShipmentRelationShipmentItemShow;
}

interface OrderRequestItemTableShow {
  product_gallery: string;
  product_name: string;
}

export interface OrderRequestTable {
  request_number: string;
  request_date: string;
  merchant: number;
  order_progress: number;
  pcs_request: number;
  pcs_confirmed: number;
  downloadDisabled: boolean;
}

export interface OrderRequestItemRelationFulfillmentTable {
  confirmed_flag: number;
  dispatch_qty: number;
  fulfill_qty: number;
  fulfillment_items_id: number;
  fulfillment_order_number: string;
  id: number;
  old_fulfillment_id: string;
  request_items_id: number;
  sale_order_number: string;
  shipped_flag: number;
  shipped_qty: number;
  sort_flag: number;
}

export interface OrderRequestItemSubTable {
  id: number;
  request_items_id: number;
  merchant_order_number: string;
  confirmation_date: string;
  merchant_invoice_number: string;
  shipping_date: string;
  service_provider: NumberOrString;
  tracking_no: string;
  shipping_qty: number;
  __show: OrderRequestItemSubTableShow;
}

interface OrderRequestItemSubTableShow {
  service_provider: string;
}

export interface OrderRequestV2ItemTable {
  article_name: string;
  article_no: string;
  brand_name: string;
  color: string;
  confirmed_qty: number;
  currency: string;
  id: NumberOrString;
  merchant_request_id: NumberOrString;
  original_price: string;
  position: string;
  rejected_qty: number;
  request_qty: number;
  shipped_qty: number;
  size: string;
  sku_id: string;
  __show: OrderRequestV2ItemTableShow;
}

interface OrderRequestV2ItemTableShow {
  currency_code: string;
  product_gallery: string;
  product_name: string;
  qty: number;
  sub_total: string;
}

export interface OrderRequestInformation {
  id: number;
  request_number: string;
  request_date: string;
  request_expiry_date: string;
  order_progress: number;
  merchant: NumberOrString;
  channel: NumberOrString;
  invoice_address: NumberOrString;
  shipping_address: NumberOrString;
  relation_items: OrderRequestItemTable[];
  __show: OrderRequestInformationShow;
}

interface OrderRequestInformationShow {
  order_progress: string;
  merchant: string;
  channel: string;
}

export interface OrderRequestOverview {
  id: NumberOrString;
  type: OrderRequestType;
  request_number: string;
  request_date: string;
  request_expiry_date: string;
  order_progress: OrderRequestProgress;
  merchant: NumberOrString;
  channel: NumberOrString;
  invoice_address: number;
  shipping_address: number;
  shipping_charges: string;
  shipping_tax_rate: number;
  priority: number;
  overall_notes: string;
  reference: string;
  relation_items: OrderRequestOverviewRelationItem[];
  relation_shipment: OrderRequestOverviewRelationItemRelationShipment[];
  __show: OrderRequestOverviewShow;
}

export interface OrderRequestOverviewRelationItem {
  id: NumberOrString;
  merchant_request_id: NumberOrString;
  position: string;
  article_no: string;
  brand_name: string;
  article_name: string;
  size: string;
  color: string;
  sku_id: string;
  request_qty: number;
  confirmed_qty: number;
  invoiced_qty: number;
  shipped_qty: number;
  rejected_qty: number;
  currency: string;
  unit_net_amount: number;
  total_net_amount: string;
  unit_gross_amount: number;
  total_gross_amount: string;
  discount_type: CurrencyType;
  discount: number;
  tax_rate: number;
  overall_notes: string;
  relation_fulfillment: OrderRequestOverviewRelationItemRelationFulfillment[];
  __show: OrderRequestOverviewRelationItemShow;
}

interface OrderRequestOverviewRelationItemShow {
  total_amount: number;
  product_name: string;
  product_gallery: string;
  tax_rates: number[];
  received: number;
  inspected: number;
}

interface OrderRequestOverviewRelationItemRelationShipment {
  id: NumberOrString;
  request_items_id: NumberOrString;
  merchant_order_number: string;
  confirmation_date: string;
  merchant_invoice_number: string;
  shipping_date: string;
  service_provider: number;
  tracking_no: string;
  shipping_qty: number;
  shipped_flag: number;
  __show: OrderRequestOverviewRelationItemRelationShipmentShow;
}

interface OrderRequestOverviewRelationItemRelationShipmentShow {
  service_provider: string;
}

export interface OrderRequestOverviewRelationItemRelationFulfillment {
  id: NumberOrString;
  request_items_id: NumberOrString;
  fulfillment_items_id: NumberOrString;
  fulfill_qty: number;
  dispatch_qty: number; //confirmed qty
  shipped_qty: number;
  confirmed_flag: number;
  shipped_flag: number;
  fulfillment_order_number: string;
  sale_order_number: string;
  sort_flag: number;
  old_fulfillment_id: string;
}

interface OrderRequestOverviewShow {
  order_progress: string;
  merchant: string;
  channel: string;
  channel_type: string;
  settlement_entity_name: string;
  invoice_address: OrderRequestOverviewShowAddress;
  shipping_address: OrderRequestOverviewShowAddress;
  sale_order_number: string[];
  external_order_number: string[];
  brand: string[];
  currency_code: string;
  total_amount: string;
  total_sku: number;
  total_pcs: number;
  total_invoice: number;
  total_shipment: number;
  representative: OrderRequestOverviewShowRepresentative;
  tax_rates: number[];
}

interface OrderRequestOverviewShowRepresentative {
  name: string;
  avatar: string;
}

export interface OrderRequestOverviewShowAddress {
  id: NumberOrString;
  parent_id: NumberOrString;
  identifier_no: string;
  name: string;
  type: number;
  vat_no: string;
  country_iso_2: string;
  city: string;
  address: string;
  zip_code: string;
  business_entity_id: NumberOrString;
  is_default: number;
}

export interface OrderRequestOverviewShipment {
  id: NumberOrString;
  merchant_request_id: NumberOrString;
  shipment_id: NumberOrString;
  shipping_date: CommonDateType;
  service_provider: NumberOrString;
  tracking_no: string;
  status: ShipmentStatus;
  pick_up_by: string;
  __show: OrderRequestOverviewShipmentShow;
  relation_shipment: any;
  relation_shipment_item: OrderRequestOverviewShipmentRelationShipmentItem[];
}

interface OrderRequestOverviewShipmentShow {
  carrier: NumberOrString;
  status: string;
  total_qty: number;
}

interface OrderRequestOverviewShipmentRelationShipmentItem {
  id: NumberOrString;
  created_at: CommonDateType;
  updated_at: CommonDateType;
  deleted_at: null;
  created_uid: NumberOrString;
  updated_uid: NumberOrString;
  deleted_uid: NumberOrString;
  request_shipment_id: NumberOrString;
  request_items_id: NumberOrString;
  shipping_qty: number;
  __show: OrderRequestOverviewShipmentRelationShipmentItemShow;
}

interface OrderRequestOverviewShipmentRelationShipmentItemShow {
  brand_name: string;
  article_no: string;
  article_name: string;
  color: string;
  size: string;
  product_name: string;
  product_gallery: string;
}

export interface OrderRequestInvoice {
  id: NumberOrString;
  merchant_request_id: NumberOrString;
  invoice_number: string;
  status: number;
  invoice_date: string;
  currency: string;
  total_amount: string;
  external_order_number: string;
  invoice_file: number;
  shipping_charges: string;
  shipping_tax_rate: number;
  merchant: NumberOrString;
  channel: string;
  overall_notes: string;
  business_entity_id: NumberOrString;
  vat_refundable: CommonSwtich;
  accounting_month: string;
  payment_date: string;
  price_calc_mode: PriceCalcMode;
  __show: OrderRequestInvoiceShow;
  relation_invoice_item: OrderRequestInvoiceRelationInvoiceItem[];
  relation_request: OrderRequestInvoiceRelationRequest;
}

export type PriceCalcMode = "net" | "gross";

interface OrderRequestInvoiceShow {
  status: string;
  invoice_file: string;
  file_size: number;
  taxRates: number[];
}

export interface OrderRequestInvoiceRelationInvoiceProductTable {
  request_number: string;
  request_date: string;
  reference: string;
  total_qty: number;
  total_net_amount: number;
  items: OrderRequestInvoiceRelationInvoiceItem[];
  isChecked: boolean;
  isExpand: boolean;
}

export interface OrderRequestInvoiceRelationInvoiceItem {
  id: NumberOrString;
  request_items_id: NumberOrString;
  article_no: string;
  brand_name: string;
  article_name: string;
  size: string;
  color: string;
  sku_id: string;
  sku_identifier: string;
  product_identifier_id: string;
  currency: string;
  unit_net_amount: number;
  unit_gross_amount: number;
  discount_type: CurrencyType;
  discount: number;
  tax_rate: number;
  qty: number;
  confirmed_qty: number;
  request_number: string;
  request_date: string;
  __show: OrderRequestInvoiceRelationInvoiceItemShow;
  isChecked: boolean;
}

interface OrderRequestInvoiceRelationInvoiceItemShow {
  channel_id: number;
  channel_name: string;
  channel_type: string;
  article_no: string;
  brand_name: string;
  size: string;
  color: string;
  product_name: string;
  product_gallery: string;
  product_identifier_id: string;
  tax_rates: number[];
  discount_rule: number;
}

interface OrderRequestInvoiceRelationRequest {
  id: NumberOrString;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  created_uid: NumberOrString;
  updated_uid: NumberOrString;
  deleted_uid: NumberOrString;
  type: OrderRequestType;
  request_number: string;
  request_date: string;
  request_expiry_date: string;
  order_progress: OrderRequestProgress;
  merchant: NumberOrString;
  channel: NumberOrString;
  invoice_address: NumberOrString;
  shipping_address: NumberOrString;
  shipping_charges: string;
  shipping_tax_rate: number;
  priority: number;
  reference: string;
  overall_notes: string;
}
